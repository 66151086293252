<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">

    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        temporary
    >
      <v-list dense nav>
        <v-list-item link :to="{name: 'Home'}">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">Avaleht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{name: 'GTI'}">
          <v-list-item-action>
            <v-icon>mdi-car</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">GTI</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{name: 'Arteon'}">
          <v-list-item-action>
            <v-icon>mdi-car</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">Arteon R</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        clipped-left
        dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>VILDERSEN.EU</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer class=justify-center>
      Being ego since 1982 - Timo Vildersen &copy; {{ currentYear }}
    </v-footer>
  </v-app>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'App',

  components: {},

  data: () => ({
    drawer: null
  }),
  created() {
    this.$vuetify.theme.dark = true
  },
  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    ...mapGetters(['currentYear'])
  }
};
</script>

<style scoped>
</style>